// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-callback-js": () => import("../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-when-js": () => import("../src/pages/when.js" /* webpackChunkName: "component---src-pages-when-js" */),
  "component---src-pages-who-js": () => import("../src/pages/who.js" /* webpackChunkName: "component---src-pages-who-js" */),
  "component---src-pages-why-js": () => import("../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */)
}

